import ReactDOM from 'react-dom';
//const { render } = require("@testing-library/react");
import './index.css';
import App from './App';

ReactDOM.render(
    <App />,
    document.getElementById('root')


);