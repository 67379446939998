import{ BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Navigation from './components/Navigation';
import Cart from './pages/Cart';

const App = () =>{
    return (
    <>
        <Router>
            <Navigation/>
            <Routes>
                <Route path='/' element={<Home/>} exact></Route>
                {/*<Route path='/About' element={<About/>}></Route>*/}
                <Route path='/Products' element={<Products/>}></Route>
                <Route path='/Cart' element={<Cart/>}></Route>
            </Routes>
        </Router>
    </>
    )
}

export default App;